<template>
<div class="row">

<div class="col-xl-6 mb-3">

<div class="card text-white text-opacity-70 h-100 overflow-hidden">

<div class="card-img-overlay d-block d-lg-none bg-blue rounded"></div>


<div class="card-img-overlay d-none d-md-block bg-blue rounded mb-n1 mx-n1" style="background-image: url(assets/img/bg/wave-bg.png); background-position: right bottom; background-repeat: no-repeat; background-size: 100%;"></div>


<div class="card-img-overlay d-none d-md-block bottom-0 top-auto">
<div class="row">
<div class="col-md-8 col-xl-6"></div>
<div class="col-md-4 col-xl-6 mb-n2">
<img src="assets/img/page/dashboard.svg" alt="" class="d-block ms-n3 mb-5" style="max-height: 310px" />
</div>
</div>
</div>


<div class="card-body position-relative">

<div class="row">

<div class="col-md-8">

<div class="d-flex">
<div class="me-auto">
<h5 class="text-white text-opacity-80 mb-3">Overall Population Addition</h5>
<h3 class="text-white mt-n1 mb-1">{{ statistics[7] }} Collections</h3>

</div>
</div>
<hr class="bg-white bg-opacity-75 mt-3 mb-3" />

<div class="row">
<div class="col-6 col-lg-5">
<div class="mt-1">
<i class="fa fa-fw fa-male fs-28px text-black text-opacity-50"></i>
</div>
<div class="mt-1">
<div>Professions</div>
<div class="fw-600 text-white">{{ statistics[6] }}</div>
</div>
</div>
<div class="col-6 col-lg-5">
<div class="mt-1">
<i class="fa fa-fw fa-retweet fs-28px text-black text-opacity-50"></i>
</div>
<div class="mt-1">
<div>No of Districts</div>
<div class="fw-600 text-white">{{ statistics[2] }}</div>
</div>
</div>
</div>
<hr class="bg-white bg-opacity-75 mt-3 mb-3" />
<div class="mt-3 mb-2">
<a href="#" @click.prevent="getstatisticssetIntervalbtn" class="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px fw-600">Get Latest Statistics</a>
</div>
<p class="fs-12px">
For the latest statistics, please click the button above. It will reload for the latest
</p>
</div>


<div class="col-md-4 d-none d-md-block" style="min-height: 380px;"></div>

</div>

</div>

</div>

</div>


<div class="col-xl-6">

<div class="row">

<div class="col-sm-6">

<div class="card mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-orange" style="min-height: 199px;">

<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
<img src="assets/img/icon/order.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
</div>


<div class="card-body position-relative">
<h5 class="text-white text-opacity-80 mb-3 fs-16px">Villages Progress Report</h5>
<h3 class="text-white mt-n1">{{ villagespaercetage.slice(0, 4) }}%</h3>
<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
 <div class="progrss-bar progress-bar-striped bg-white" v-bind:style="{ 'width': 'url(' + villagespaercetage + ')' }"></div>
</div>
<div class="text-white text-opacity-80 mb-4">Comparing to the number of Villages in Uganda</div>

</div>

</div>


<div class="card mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-teal" style="min-height: 199px;">

<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
<img src="assets/img/icon/visitor.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
</div>


<div class="card-body position-relative">
<h5 class="text-white text-opacity-80 mb-3 fs-16px">Parish Progress Report</h5>
<h3 class="text-white mt-n1">{{ parishpaercetage.slice(0, 4) }}%</h3>
<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
<div class="progrss-bar progress-bar-striped bg-white" v-bind:style="{ 'width': 'url(' + parishpaercetage + ')' }"></div>
</div>
<div class="text-white text-opacity-80 mb-4">Comparing to the number of Parish in Uganda</div>
</div>

</div>

</div>


<div class="col-sm-6">

<div class="card mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-pink" style="min-height: 199px;">

<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
<img src="assets/img/icon/email.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
</div>


<div class="card-body position-relative">
<h5 class="text-white text-opacity-80 mb-3 fs-16px">Collection Progress Report</h5>
<h3 class="text-white mt-n1">{{ collectionpaercetage.slice(0, 4)}}%</h3>
<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
<div class="progrss-bar progress-bar-striped bg-white" v-bind:style="{ 'width': 'url(' + collectionpaercetage + ')' }"></div>
</div>
<div class="text-white text-opacity-80 mb-4">Comparing to the Population in Uganda</div>
</div>

</div>


<div class="card mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-indigo" style="min-height: 199px;">

<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
<img src="assets/img/icon/browser.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
</div>


<div class="card-body position-relative">
<h5 class="text-white text-opacity-80 mb-3 fs-16px">Professions Progress Report</h5>
<h3 class="text-white mt-n1">{{ professionpaercetage.slice(0, 4)}}%</h3>
<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
<div class="progrss-bar progress-bar-striped bg-white" v-bind:style="{ 'width': 'url(' + professionpaercetage + ')' }"></div>
</div>
<div class="text-white text-opacity-80 mb-4">Comparing to the Number of Professions in Uganda</div>
</div>

</div>

</div>

</div>

</div>

</div>













<div class="row">

<div class="col-xl-3">
<div class="card mb-3 flex-1">

<div class="card-body">
<div class="d-flex mb-3">
<div class="flex-grow-1">
<h5 class="mb-1">Total Counties</h5>
<div>Number of Counties </div>
</div>
<a href="#"  @click.prevent="getstatisticssetIntervalbtn" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
</div>
<div class="d-flex">
<div class="flex-grow-1">
<h3 class="mb-1">{{ statistics[2] }}</h3>

</div>
<div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
<i class="fa fa-user fa-lg text-primary"></i>
</div>
</div>
</div>

</div>



</div>


<div class="col-xl-3">
<div class="card mb-3 flex-1">
<div class="card-body">
<div class="d-flex mb-3">
<div class="flex-grow-1">
<h5 class="mb-1">Total Divisions</h5>
<div>Number of Divisions</div>
</div>
<a href="#"  @click.prevent="getstatisticssetIntervalbtn" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
</div>
<div class="d-flex">
<div class="flex-grow-1">
<h3 class="mb-1">{{ statistics[3] }}</h3>

</div>
<div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
<i class="fa fa-user fa-lg text-primary"></i>
</div>
</div>
</div>

</div>
</div>




<div class="col-xl-3">
<div class="card mb-3 flex-1">
<div class="card-body">
<div class="d-flex mb-3">
<div class="flex-grow-1">
<h5 class="mb-1">Total Parishes</h5>
<div>Number of Parishes</div>
</div>
<a href="#"  @click.prevent="getstatisticssetIntervalbtn" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
</div>
<div class="d-flex">
<div class="flex-grow-1">
<h3 class="mb-1">{{ statistics[4] }}</h3>

</div>
<div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
<i class="fa fa-user fa-lg text-primary"></i>
</div>
</div>
</div>

</div>
</div>



<div class="col-xl-3">
<div class="card mb-3 flex-1">
<div class="card-body">
<div class="d-flex mb-3">
<div class="flex-grow-1">
<h5 class="mb-1">Total Villages</h5>
<div>Number of Villages</div>
</div>
<a href="#" @click.prevent="getstatisticssetIntervalbtn" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
</div>
<div class="d-flex">
<div class="flex-grow-1">
<h3 class="mb-1">{{ statistics[5] }}</h3>

</div>
<div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
<i class="fa fa-user fa-lg text-primary"></i>
</div>
</div>
</div>

</div>
</div>







</div>

</template>


<script>
import {mapGetters, mapActions } from 'vuex'
export default {


    computed:{
        ...mapGetters(['msg', 'error','pageloader','loading', 'statistics']),

        villagespaercetage(){
            return ((this.statistics[5]/ 749481) * 100) + '%';
        },

        parishpaercetage(){
            return ((this.statistics[4]/ 10595) * 100) + '%';
        },

        collectionpaercetage(){
            return ((this.statistics[7]/ 45740000) * 100) + '%';
        },

        professionpaercetage(){
            return ((this.statistics[6]/ 9000) * 100) + '%';
        }

    },


    methods:{
        ...mapActions(['getstatistics']),


        getstatisticssetInterval(){
            setInterval(function () {
                this.getstatistics();
            }, 60000);
        },

        getstatisticssetIntervalbtn(){
             this.getstatistics();
        },

    },


    mounted(){
        this.getstatisticssetInterval()
    },

    created(){
        this.getstatistics();
        
    }

}
</script>